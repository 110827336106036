<template>
  <transition name="slideDown">
    <div
      v-if="show"
      class="d-flex align-items-center justify-content-center offer-notify _success"
    >
      <img :src="require('@/assets/image/checked.svg')" alt="checked" />
      <span class="f-w6">エントリーしました</span>
    </div>
  </transition>
</template>

<script>
export default {
  name: "OfferNotifySuccess",
  data() {
    return {
      show: false
    };
  },
  methods: {
    openNotify() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 4000);
    },
    hideNotify() {
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.offer-notify {
  width: 100%;
  max-width: 1080px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 1002;
  background-color: #18b87c;
  height: 32px;
  span {
    color: #fff;
  }
  img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}

.slideDown {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(-50%) translateY(-50px);
  }

  &-enter-active,
  &-leave-active {
    transition: all ease 0.5s;
  }

  &-enter-to,
  &-leave {
    transform: translateX(-50%) translateY(0);
  }
}
</style>
