<template>
  <div class="confirm-cancel">
    <p class="f-w3">本当にキャンセルしてよろしいでしょうか。</p>
    <div class="d-flex">
      <button class="_btn f-w3 _cancel" @click="cancelOffer()">
        はい
      </button>
      <button class="_btn f-w3 _okay" @click="denyCancelOffer()">
        キャンセル
      </button>
    </div>
  </div>
</template>

<script>
import service from "@/utils/axios";
import { ADS_API_URL } from "@/api/api";

export default {
  name: "OfferListConfirmCancel",
  props: ["offerId"],
  methods: {
    denyCancelOffer() {
      this.$emit("close-modal");
    },
    async cancelOffer() {
      const { data } = await service({
        url: ADS_API_URL + "/" + this.offerId + "/decline",
        method: "PUT"
      })
        .then(response => response)
        .catch(err => {
          return {
            data: {
              error: err
            }
          };
        });

      if (!data?.error) {
        this.$emit("sent-request");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.confirm-cancel {
  padding-top: 40px;
  p {
    margin-bottom: 35px;
  }

  ._btn {
    flex: 1;
    height: 45px;
    border-radius: 5px;

    &._cancel {
      color: #000000;
      background-color: #e4e4e4;
      margin-right: 11px;
    }

    &._okay {
      background-color: #464d77;
      color: #fff;
    }
  }
}
</style>
