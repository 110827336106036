<template>
  <div class="cast-offer__filter d-flex">
    <div
      class="_item d-flex align-items-center justify-content-center"
      v-for="(item, key) of options"
      :key="key"
      :class="item.id === value ? 'active' : ''"
      @click="chooseFilterOption(item.id)"
    >
      <span class="f-w6 position-relative">
        {{ item.title }}
        <div
          v-if="
            (totalNotReadAds['designate'] > 0 && item.id === 2) ||
              (totalNotReadAds['match'] > 0 && item.id === 4)
          "
          class="notify position-absolute"
        ></div>
      </span>
    </div>
  </div>
</template>

<script>
import { offerTabs } from "../../constants/offer";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "OfferListFilter",
  props: ["value"],
  data() {
    return {
      options: offerTabs
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      totalNotReadAds: "auth/totalNotReadAds"
    })
  },
  methods: {
    async chooseFilterOption(typeId) {
      this.$emit("choose-filter", typeId);
      this.$router.replace({
        path: this.$route.path,
        query: { tab: this.options[typeId - 1].url }
      });
      switch (typeId) {
        case 2:
          Vue.set(this.totalNotReadAds, "designate", 0);
          break;

        case 4:
          Vue.set(this.totalNotReadAds, "match", 0);
          break;
      }
    }
  },
  created() {
    this.$store.dispatch("auth/getTotalNotReadAds");
  }
};
</script>

<style lang="scss" scoped>
.cast-offer {
  &__filter {
    width: 100%;
    border-bottom: 1px solid #b9b9b9;
    position: fixed;
    margin: auto;
    top: 42;
    background-color: #ffffff;
    z-index: 1;
    ._item {
      flex: 1;
      padding: 15px 0;
      position: relative;
      cursor: pointer;
      user-select: none;
      span {
        color: #9f9f9f;
        font-size: 14px;
      }
      .notify {
        border-radius: 50%;
        background-color: #eb5050;
        height: 15px;
        width: 15px;
        top: -5px;
        right: -15px;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background-color: #000000;
        bottom: -1px;
        left: 0;
        transition: 0.5s ease;
        transform: scale(0.7);
        opacity: 0;
      }

      &.active {
        span {
          color: #000000;
        }
        &::after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .notify {
    top: 0;
  }
  .cast-offer {
    &__filter {
      width: calc(806px);
      position: fixed;
      left: 49%;
      transform: translateX(-49%);
      margin: 0 auto;
      ._item {
        span {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
