// Tab in offer-list
export const offerTabs = [
  // {
  //   id: 1,
  //   title: "受付中",
  //   url: "open"
  // },
  {
    id: 2,
    title: "招待",
    url: "designation"
  },
  {
    id: 3,
    title: "エントリー中",
    url: "application"
  },
  {
    id: 4,
    title: "成立",
    url: "match"
  }
];

// offer time for search
export const offerTime = [
  {
    id: 1,
    title: "5 時台〜",
    value: "05:00:00"
  },
  {
    id: 2,
    title: "6 時台〜",
    value: "06:00:00"
  },
  {
    id: 3,
    title: "7 時台〜",
    value: "07:00:00"
  },
  {
    id: 4,
    title: "8 時台〜",
    value: "08:00:00"
  },
  {
    id: 5,
    title: "9 時台〜",
    value: "09:00:00"
  },
  {
    id: 6,
    title: "10 時台〜",
    value: "10:00:00"
  },
  {
    id: 7,
    title: "11 時台〜",
    value: "11:00:00"
  }
];

// offer status
export const offerStatus = [
  {
    status: 0,
    title: "Superオファー中"
  },
  {
    status: 1,
    title: "キャンセル済み"
  },
  {
    status: 2,
    title: "自動キャンセル済み"
  },
  {
    status: 3,
    title: "確定済み"
  },
  {
    status: 4,
    title: "キャンセル済み"
  },
  {
    status: 5,
    title: "キャンセル済み"
  },
  {
    status: 6,
    title: "集合済み"
  },
  {
    status: 7,
    title: "終了"
  },
  {
    status: 8,
    title: "終了"
  }
];
