<template>
  <transition name="slideUp">
    <div v-if="data" class="cast-offer__item">
      <div class="d-flex align-items-center justify-content-between _user">
        <div class="d-flex align-items-center profile_item">
          <div
            class="d-flex align-items-center"
            @click.prevent="redirectProfile(data.user.user_id)"
          >
            <AvatarBox
              :imgUrl="data.user.image_url"
              imgSize="79px"
              :imgAlt="data.user.nickname"
            />

            <div class="d-flex flex-column profile-info align-items-start">
              <!-- only for offer tab 4 -->
              <div v-if="tabType === 4 && showMore" class="d-flex">
                <div class="dot f-w3" v-if="statusOffer === 3">
                  {{ data.status }}
                </div>
                <div class="dot f-w3 not-active" v-else>
                  {{ data.status }}
                </div>
              </div>
              <div
                class="d-flex _info"
                :class="{
                  'flex-column': tabType !== 4 || !showMore,
                  'align-items-end': tabType === 4 && showMore
                }"
              >
                <span class="_name f-w6">{{ data.user.nickname }}</span>
                <span class="_age f-w3 text-left">{{ data.user.age }}歳</span>
              </div>
            </div>
          </div>
          <!-- only for offer tab 1/2 -->
          <button
            v-if="(tabType === 1 || tabType === 2) && windowWidth >= 1200"
            class="btn-entry f-w3"
            :class="{
              'type-one': tabType === 1,
              'd-none':
                $dayjs(new Date()).format('YYYY-MM-DD') >=
                $dayjs(
                  new Date(data.scheduled_meeting_datetime.replace(/-/g, '/'))
                ).format('YYYY-MM-DD')
            }"
            @click="checkIdentityCreditCard(data.ad_id)"
          >
            エントリー
          </button>
          <!-- remove  -->
        </div>

        <div
          class="d-flex flex-column"
          :class="showMore ? 'align-items-center' : ''"
        >
          <div class="bd-point position-relative">
            <p class="mb-0 bd-point__p f-w3">獲得コイン</p>
            <span class="tooltip-target f-w6"> {{ pointValue }} </span>
            <div
              v-if="
                showMore && (offerTab == 1 || offerTab == 2) && isShowToolTip
              "
              class="popover-content d-flex"
            >
              <div class="tooltip-arrow position-absolute"></div>
              <div class="content text-center f-w3 position-absolute">
                <div class="text-right">
                  <a @click="closeToolTip()">
                    <img
                      :src="require('@/assets/image/close-tooltip.svg')"
                      alt="close popup"
                    />
                  </a>
                </div>
                <p>こちらが獲得できるコインになります。</p>
                <p>コインは獲得すると現金と交換することがきます。</p>
                <p>１コイン＝1円</p>
                <p class="faq" @click="$router.push('my-page/faq')">
                  詳しくはこちら
                </p>
              </div>
            </div>
            <!-- <v-popover
              :open="
                showMore && (offerTab == 1 || offerTab == 2)
              "
              :popoverBaseClass="'tooltip'"
              :popoverInnerClass="'popover-inner custom-popover'"
              :trigger="''"
              placement="bottom"
            >
              <span class="tooltip-target f-w6"> {{ pointValue }}コイン </span>
              <template slot="popover">
                <div class="text-right">
                  <a v-close-popover class="text-right" @click="closeToolTip">
                    <img
                      :src="require('@/assets/image/close-tooltip.svg')"
                      alt="close popup"
                    />
                  </a>
                </div>
                <div class="content tool-tip text-center f-w3">
                  <p>こちらが獲得できるコインになります。</p>
                  <p>コインは獲得すると現金と交換することがきます。</p>
                  <p>１コイン＝{{ pointValue }}円</p>
                  <p class="faq" @click="$router.push('my-page/faq')">
                    詳しくはこちら
                  </p>
                </div>
              </template>
            </v-popover> -->
          </div>
          <!-- only for offer tab 1/2 -->
          <button
            v-if="(tabType === 1 || tabType === 2) && windowWidth < 1200"
            class="btn-entry f-w3"
            :class="tabType === 1 ? 'type-one' : ''"
            @click="checkIdentityCreditCard(data.ad_id)"
          >
            エントリー
          </button>
          <!-- remove  -->
          <!-- <div v-if="tabType === 3" class="applying">応募中</div> -->
        </div>
      </div>

      <div class="_detail">
        <div
          class="
            _detail__item
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
          "
        >
          <span>ラウンド日/プレー開始時間</span>
          <span>{{ timeFormatForTab }}時頃</span>
        </div>
        <!-- default hidden  -->
        <div
          class="
            _detail__item
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
          "
          v-if="showMore"
        >
          <span>お相手の人数/女性Superオファー人数</span>
          <span
            >{{ data.male_participants }}/{{ data.female_participants }}</span
          >
        </div>
        <div
          class="
            _detail__item
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
          "
        >
          <!-- area  -->
          <span>ピックアップエリア</span>
          <span>{{ data.golf_area }} </span>
        </div>
        <div
          class="
            _detail__item
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
          "
        >
          <!-- plan  -->
          <span>プラン</span>
          <span>{{ data.plan }}</span>
        </div>
        <div
          class="
            _detail__item
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
          "
        >
          <!-- Golf course -->
          <span>ゴルフ場</span>
          <span
            >{{ data.golf_course }}
            <button class="btn-map" @click="openModal()" v-if="showMore">
              <img :src="require('@/assets/image/map.svg')" alt="map" /></button
          ></span>
        </div>
        <!-- default hidden  -->
        <template v-if="showMore">
          <div
            class="
              _detail__item
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <!-- Golf rank -->
            <span>ゴルフレベル</span>
            <span v-if="data.female_golf_ranks">{{
              data.female_golf_ranks.join("、")
            }}</span>
          </div>
          <div
            class="
              _detail__item
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <!-- Prego rank  -->
            <span>ランク</span>
            <span v-if="data.female_prego_ranks">{{
              getPregoRank("female_prego_ranks")
            }}</span>
          </div>
          <div
            class="
              _detail__item
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <span>ゴルフシチュエーション</span>
            <span v-if="data.attitudes">{{ data.attitudes.join("、") }}</span>
          </div>
          <div
            class="
              _detail__item
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <!-- age  -->
            <span>年齢</span>
            <span v-if="data.preferred_ages">{{
              data.preferred_ages.join("、")
            }}</span>
          </div>
          <div
            class="
              _detail__item
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <!-- type  -->
            <span>タイプ</span>
            <span v-if="data.appearances">{{
              data.appearances.join("、")
            }}</span>
          </div>
          <div
            class="
              _detail__item
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <!-- style -->
            <span>スタイル</span>
            <span v-if="data.figures">{{ data.figures.join("、") }}</span>
          </div>
          <div
            class="
              _detail__item
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <span>その他</span>
            <span v-if="data.other_preferences">{{
              data.other_preferences.join("、")
            }}</span>
          </div>
          <div
            class="
              _detail__item
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
            "
          >
            <span>応募人数/Superオファー人数</span>
            <span
              >{{ data.number_of_applicants }}/{{
                data.female_participants
              }}</span
            >
          </div>
        </template>
        <div
          v-if="tabType === 3 && !showMore"
          class="
            _detail__item
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
          "
        >
          <span>応募人数/Superオファー人数</span>
          <span
            >{{ data.number_of_applicants }}/{{
              data.female_participants
            }}</span
          >
        </div>
        <!-- <div
          class="
            _detail__item
            d-flex
            flex-wrap
            align-items-center
            justify-content-between
          "
          id="point"
        >
          <span>獲得コイン</span>
          <span>{{ pointValue }}コイン</span>
        </div> -->
      </div>
      <!-- default hidden -->
      <template v-if="showMore">
        <div v-if="data.request" class="_detail__item" id="note">
          <p class="text-left f-w3 text-detail">
            {{ data.request }}
          </p>
        </div>

        <b-button
          v-if="tabType === 3 && !data.canceled_at"
          class="btn btn-cancel f-w3"
          @click="sendCancelRequest(data.ad_id)"
          >キャンセル</b-button
        >
      </template>
      <!-- <div
        class="_detail__item d-flex align-items-center justify-content-center"
      >
        <div
          class="btn-collapse"
          @click="changeExpand()"
          :class="{ active: showMore }"
        >
          <img :src="require('@/assets/image/expand.svg')" alt="expand" />
        </div>
      </div> -->
      <div
        class="
          _detail__item
          detail__btn
          d-flex
          align-items-center
          justify-content-center
        "
      >
        <div v-if="!showMore" @click="changeExpand()" class="f-w3 w-100">
          詳細
          <b-icon icon="chevron-down" class="ml-3"></b-icon>
        </div>
        <div v-else @click="changeExpand()" class="w-100">
          閉じる <b-icon icon="chevron-up" class="ml-3"></b-icon>
        </div>
      </div>
      <ModalLayout ref="offerCancelConfirm">
        <OfferListConfirmCancel
          :offerId="data.ad_id"
          @close-modal="$refs.offerCancelConfirm.closeModal()"
        />
      </ModalLayout>
      <ModalLayout ref="modal-show-map">
        <p class="modal-logout-title f-w3">
          Google Mapを起動しますが、よろしいでしょうか？
        </p>
        <div class="d-flex justify-content-center">
          <button @click.prevent="requestOpenMap" class="btn-logout">
            はい
          </button>
          <button @click="cancelShowMap" class="btn-cancel-logout">
            いいえ
          </button>
        </div>
      </ModalLayout>
      <ModalLayout ref="identifyPopup" :defaultStyle="false">
        <IdentifyPopup
          @identify="identify"
          :identity_status="me.identity_status"
          :sex="me.sex"
          :page="'other'"
        />
      </ModalLayout>
      <ModalLayout ref="notReadMatchingOrder" :defaultStyle="false">
        <div class="noti-matching">
          <p>プレー代のお支払いは男性がしてくれます。</p>
          <p>プレー代をお支払いいただきたくない場合は、</p>
          <p>当日お相手に申し出て、自らお支払いする事も</p>
          <p>可能です。</p>
        </div>
      </ModalLayout>
    </div>
  </transition>
</template>

<script>
import AvatarBox from "../Common/AvatarBox.vue";
import OfferListConfirmCancel from "./OfferListConfirmCancel.vue";
import { NumberFormat } from "@/utils/number";
import IdentifyPopup from "@/components/IdentifyPopup.vue";
import { mapGetters } from "vuex";
import { offerTabs } from "../../constants/offer";
import Vue from "vue";

export default {
  components: { AvatarBox, OfferListConfirmCancel, IdentifyPopup },
  props: {
    data: {
      type: Object,
      require: true
    },
    tabType: {
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      me: "auth/user"
    }),
    timeFormatForTab() {
      return this.data.scheduled_meeting_datetime
        .replace(/-/g, "/")
        .substring(0, this.data.scheduled_meeting_datetime.length - 6);
    },
    pointValue() {
      return NumberFormat.format(this.data.assumed_bd_points);
    },
    statusOffer() {
      let status = null;
      switch (this.data.status) {
        // open
        case "Superオファー中":
          status = 0;
          break;

        // cancel
        case "キャンセル済み":
          status = 1;
          break;

        // auto cancel
        case "自動キャンセル済み":
          status = 2;
          break;

        // matched
        case "確定済み":
          status = 3;
          break;

        // meet
        case "集合済み":
          status = 6;
          break;

        // end
        case "終了":
          status = 7;
          break;
      }
      return status;
    }
  },
  methods: {
    checkIdentityCreditCard(ad_id) {
      this.isShowToolTip = false;
      if (this.me.identity_status != 3) {
        this.$refs.identifyPopup.openModal();
        return;
      }
      this.sendRequestEntry(ad_id);
    },
    identify() {
      this.$router.push({ name: "CastMyPageIdentifyProfile" });
      this.$refs.identifyPopup.closeModal();
      return;
    },
    redirectProfile(userId) {
      this.$router.push({
        path: `/cast/profile/${userId}`
      });
    },
    getPregoRank(property) {
      let name = "";
      let arr = this.data[property];
      arr.forEach(element => {
        switch (element) {
          case "WHITE":
            name += "新規" + "、";
            break;
          case "GREEN":
            name += "通常" + "、";
            break;
          case "BLUE":
            name += "優良" + "、";
            break;
          case "RED":
            name += "超優良" + "、";
            break;
          default:
            name += element + "、";
            break;
        }
      });
      return name.substring(0, name.length - 1);
    },
    changeExpand() {
      this.showMore = !this.showMore;
      const localStorageName = "tooltip_user_" + this.me.user_id;
      // const tooltip = JSON.parse(localStorage.getItem(localStorageName));
      if (localStorage.getItem(localStorageName) == 0) {
        // tooltip.push(this.data.ad_id);
        // localStorage.setItem(localStorageName, JSON.stringify(tooltip));
        localStorage.setItem(localStorageName, 1);
        if (!this.showMore) {
          this.isShowToolTip = false;
        }
      } else {
        this.isShowToolTip = false;
      }
      if (this.data.is_read === 0 && this.showMore && this.tabType === 4) {
        this.$refs.notReadMatchingOrder.openModal();
        Vue.set(this.data, "is_read", 1);
      }
    },
    sendRequestEntry(adId) {
      this.$emit("entry-offer", adId);
    },
    sendCancelRequest(adId) {
      this.$emit("cancel-offer", adId);
    },
    requestOpenMap() {
      this.$emit("open-map", this.data.golf_course);
    },
    openModal() {
      this.$refs["modal-show-map"].openModal();
    },
    cancelShowMap() {
      this.$refs["modal-show-map"].closeModal();
    },
    closeToolTip() {
      const localStorageName = "tooltip_user_" + this.me.user_id;
      // const tooltip = JSON.parse(localStorage.getItem(localStorageName));
      if (localStorage.getItem(localStorageName) == 0) {
        // tooltip.push(this.data.ad_id);
        // localStorage.setItem(localStorageName, JSON.stringify(tooltip));
        // tooltip.push(this.data.ad_id);
        localStorage.setItem(localStorageName, 1);
      }
      this.isShowToolTip = false;
    }
  },
  data() {
    return {
      showMore: false,
      windowWidth: window.innerWidth,
      isShowToolTip: false,
      offerTab: 1
    };
  },
  mounted() {
    this.showMore = false;
  },
  created() {
    let tab = this.$route?.query?.tab;
    if (tab) {
      let tabActive = offerTabs.find(item => item.url === tab);
      this.offerTab = tabActive.id;
    }
    const localStorageName = "tooltip_user_" + this.me.user_id;
    this.isShowToolTip =
      localStorage.getItem(localStorageName) == 0 ? true : false;
    // const tooltip = JSON.parse(localStorage.getItem(localStorageName));
    // this.isShowToolTip = !tooltip.includes(this.data.ad_id) ? true : false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cast/offerListItem.scss";

.content {
  top: calc(100% + 5px);
  right: -5px;
  max-width: 400px;
  width: calc(100vw - 48px);
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  background: #ffdfc7 0% 0% no-repeat padding-box;
  p {
    margin-bottom: 0;
  }
  .faq {
    color: #464d77;
    text-decoration: underline;
    margin-bottom: 10px;
  }
}

.tooltip-arrow {
  left: calc(50% - 5px);
  border-width: 0px 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-style: solid;
  border-color: #ffdfc7;
}

.noti-matching {
  margin: 37px 10px;
  font-size: 14px;
}

@media screen and (min-width: 1200px) {
  .content {
    font-size: 14px;
  }

  .noti-matching {
    font-size: 16px;
  }
}
</style>
