<template>
  <transition name="slideRight">
    <div v-if="show" class="offer-search">
      <HeaderCommon title="絞り込み検索">
        <template v-slot:headerButton>
          <button class="btn btn-close" @click="closeSearch()">
            <img
              :src="require('@/assets/image/close.svg')"
              alt="close search"
            />
          </button>
        </template>
      </HeaderCommon>
      <div class="offer-search__content">
        <form @submit.prevent="handleSearch()">
          <!-- Start time -->
          <div class="_form-group d-flex flex-column">
            <label class="f-w6">予定開始時間</label>
            <OfferSearchSelect
              :options="scheduleOptions"
              v-model="scheduleValue"
              @select-option="scheduleValue = $event"
            />
          </div>

          <!-- Area -->
          <div class="_form-group d-flex flex-column">
            <label class="f-w6">ピックアップエリア</label>
            <OfferSearchSelectMulti
              :options="Areas"
              v-model="areaValue"
              @select-option="areaValue = $event"
            />
          </div>

          <!-- Plan -->
          <div class="_form-group d-flex flex-column">
            <label class="f-w6">プラン</label>
            <OfferSearchSelectMulti
              :options="Plans"
              v-model="planValue"
              @select-option="planValue = $event"
            />
          </div>

          <!-- Female part -->
          <div class="_form-group d-flex flex-column">
            <label class="f-w6">Superオファー人数</label>
            <OfferSearchSelect
              :options="applicantOptions"
              v-model="applicantValue"
              @select-option="applicantValue = $event"
            />
          </div>

          <!-- Golf Rank -->
          <div class="_form-group d-flex flex-column">
            <label class="f-w6">ゴルフレベル</label>
            <OfferSearchSelectMulti
              :options="Ranks"
              v-model="golfRankValue"
              @select-option="golfRankValue = $event"
            />
            <!-- <input type="text" v-model="golfRankValue" /> -->
          </div>
          <button class="btn-simple">
            検索する
          </button>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import HeaderCommon from "../Common/HeaderCommon.vue";
import OfferSearchSelect from "./OfferSearchSelect.vue";
import OfferSearchSelectMulti from "./OfferSearchSelectMulti.vue";
import { offerTime } from "../../constants/offer";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OfferSearch",
  components: {
    HeaderCommon,
    OfferSearchSelect,
    OfferSearchSelectMulti
  },
  props: {
    value: {
      type: Object
    },
    typeTab: {
      type: Number
    },
    show: {
      type: Boolean
    },
    page: {
      type: Number
    },
    pageSize: {
      type: Number
    }
  },
  data() {
    return {
      scheduleValue: null,
      scheduleOptions: offerTime,
      areaValue: [],
      planValue: [],
      applicantValue: null,
      applicantOptions: [
        {
          id: 1,
          title: 1
        },
        {
          id: 2,
          title: 2
        },
        {
          id: 3,
          title: 3
        },
        {
          id: 4,
          title: 4
        },
        {
          id: 5,
          title: 5
        },
        {
          id: 6,
          title: 6
        },
        {
          id: 7,
          title: 7
        },
        {
          id: 8,
          title: 8
        },
        {
          id: 9,
          title: 9
        },
        {
          id: 10,
          title: 10
        }
      ],
      golfRankValue: []
    };
  },
  computed: {
    ...mapGetters({
      Areas: "offerManagement/Areas",
      Plans: "offerManagement/Plans",
      Ranks: "offerManagement/Ranks",
      user: "auth/user"
    })
  },
  watch: {
    // change tab to reset data in search popup
    typeTab: function() {
      this.golfRankValue = [];
      this.areaValue = [];
      this.planValue = [];
      this.scheduleValue = null;
      this.applicantValue = null;
    }
  },
  methods: {
    ...mapActions({
      getList: "offerManagement/getList"
    }),
    closeSearch() {
      this.$emit("close-search");
    },
    handleSearch() {
      // list golf area ids
      let golf_area_ids = [];
      this.areaValue.map(item => {
        golf_area_ids.push(this.Areas[item].id);
      });

      //list plan ids
      let plan_ids = [];
      this.planValue.map(item => {
        plan_ids.push(this.Plans[item].id);
      });

      // time
      let time = this.scheduleOptions[this.scheduleValue]?.value;

      // female participants
      let female_part = this.applicantOptions[this.applicantValue]?.title ?? "";

      let params = {
        page: 1,
        perpage: this.pageSize,
        user_id: this.user.user_id,
        type: this.typeTab,
        time: time,
        golf_area_ids: golf_area_ids,
        plan_ids: plan_ids,
        female_participants: female_part,
        female_golf_rank_id: this.golfRankValue.join(","),
        commit_type: "SET_OFFER_LIST_RESET"
      };
      // await this.getList(params);
      this.$emit("update-params", params);
      this.closeSearch();
    }
  }
};
</script>

<style lang="scss">
.offer-search {
  background-color: #f5f5f5;
  width: 100%;
  max-width: 1080px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;
  z-index: 1001;
  top: 0;

  .app-container {
    &__content {
      padding-bottom: 0;
    }
  }

  .header {
    background-color: #f5f5f5;
    max-width: 1080px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 65px 19px;
  }
  form {
    max-width: 441px;
    margin: 0 auto;
    ._form-group {
      margin-bottom: 20px;
      label {
        margin-bottom: 10px;
        text-align: left;
      }

      input {
        height: 40px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        padding: 0 18px;
        font-family: "Hiragino Kaku Gothic Pro W3";
      }
    }

    .btn-simple {
      margin-top: 10px;
    }
  }

  .btn-close {
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 19px;
    img {
      width: 14px;
      height: 14px;
      display: block;
    }
  }
}

.slideRight {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(-50%) translateY(50px);
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.5s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@media screen and (min-width: 1200px) {
  .offer-search {
    &__content {
      padding-top: 142px;
    }
  }
}

@media screen and (max-width: 768px) {
  .offer-search {
    &__content {
      padding-bottom: 142px;
    }
  }
}
</style>
