<template>
  <div class="order-confirm d-flex flex-column align-items-center">
    <span class="f-w6 _title">ラウンド予定日時とプラン</span>
    <span class="f-w6 _time">{{ data.schedule }}</span>
    <span class="fs-14 f-w3 _time-play">{{ data.plan }}</span>
    <div class="d-flex align-items-center policy">
      <button class="policy__link f-w6" @click="$refs.offerTerm.openModal()">
        ご利用規約
      </button>
      <span class="f-w6">と</span>
      <button
        class="policy__link f-w6"
        @click="$refs.offerPrecaution.openModal()"
      >
        注意事項
      </button>
    </div>

    <AppCheckbox
      v-model="acceptPolicy"
      title="同意する"
      @click-checkbox="acceptPolicy = $event"
    />

    <button class="btn-simple" :disabled="!acceptPolicy" @click="handleEntry()">
      エントリーする
    </button>

    <ModalLayout ref="offerTerm" :useLargeSize="true" :defaultStyle="false">
      <OfferListItemConfirmTerm />
    </ModalLayout>
    <ModalLayout
      ref="offerPrecaution"
      :useLargeSize="true"
      :defaultStyle="false"
    >
      <OfferListItemConfirmPrecaution />
    </ModalLayout>
  </div>
</template>

<script>
import OfferListItemConfirmTerm from "./OfferListItemConfirmTerm.vue";
import OfferListItemConfirmPrecaution from "./OfferListItemConfirmPrecaution.vue";
import AppCheckbox from "@/views/Common/AppCheckbox.vue";
import service from "@/utils/axios";
import { ADS_API_URL } from "@/api/api";

export default {
  components: {
    OfferListItemConfirmTerm,
    OfferListItemConfirmPrecaution,
    AppCheckbox
  },
  name: "OfferListItemConfirm",
  props: ["data"],
  data() {
    return {
      acceptPolicy: false
    };
  },
  methods: {
    async handleEntry() {
      const { data } = await service({
        url: ADS_API_URL + "/" + this.data.ad_id + "/apply",
        method: "POST"
      })
        .then(response => response)
        .catch(error => {
          throw error;
        });

      if (!data?.error) {
        this.$emit("sent-request");
      }
    }
  }
};
</script>

<style lang="scss">
.policy {
  margin-bottom: 30px;
  button,
  span {
    color: #1973db;
  }

  button {
    background-color: transparent;
    position: relative;
    padding: 0;

    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      width: 100%;
      height: 1px;
      background-color: #1973db;
      left: 0;
    }
  }

  span {
    padding: 0 8px;
  }
}

.app-checkbox {
  position: relative;
  padding-left: 25px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &__checkmark {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #464d77;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 6px;
      top: 25%;
      left: 50%;
      transform: translateX(-50%) rotate(-50deg);
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      opacity: 0;
    }
  }

  input:checked ~ .app-checkbox__checkmark {
    background-color: #464d77;
  }
  input:checked ~ .app-checkbox__checkmark:after {
    opacity: 1;
  }
}

.order-confirm {
  padding: 20px 0;
  width: 337px;
  .app-checkbox {
    margin-bottom: 20px;
    font-family: "Hiragino Kaku Gothic Pro W3";
    font-size: 14px;
  }

  > ._title {
    margin-bottom: 10px;
  }

  ._time {
    margin-bottom: 25px;
  }

  ._time-play {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .order-confirm {
    width: 465px;
    .app-modal {
      &__close {
        img {
          width: 16px;
        }
      }
    }

    > ._title,
    ._time {
      font-size: 20px;
    }

    ._time-play,
    .policy {
      font-size: 18px;
    }

    .app-checkbox {
      font-size: 16px;
    }
  }
}
</style>
