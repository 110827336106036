<template>
  <div class="offer-policy d-flex flex-column align-items-center">
    <span class="f-w6 _title">ご利用規約</span>
    <div class="w-100 _content">
      <p>
        ・Superオファー中の案件にエントリーする場合、同じ日付の案件に複数エントリーすることが可能です。同日で複数エントリーされた場合、1つでもマッチングが成立した場合、同日のその他エントリーはすべて自動でキャンセルされます。
      </p>
      <p>
        ・マッチングが成立するまでは、女性は自由にエントリーすること、キャンセルすることが可能です。
      </p>
      <p>
        ・男性のマッチングの仕組みとして、２名Superオファーがあった場合、2名以上エントリーしている人がいないと、マッチング成立させることが出来ません。
      </p>
      <p>
        ・男性がSuperオファーを出したものにエントリーをしたが、Superオファーが集まらず、自動キャンセルになることや、男性自身の都合により、マッチングをせずに案件を取り下げ、キャンセルする場合もございます。
      </p>
      <p>
        マッチングが成立したのにも関わらず、男性が開催日7日以内にキャンセルした場合は支払ポイントの50%の有償、2日前のキャンセルをした場合は支払ポイントの100%が有償になっておりますので、その場合女性は獲得ポイントをその中から付与されます。
        男性がキャンセルした 50%有償の場合→50%のポイントを全て女性が獲得できる。
        100%有償の場合→本来そのマッチングが成立することによって得られるポイントを獲得できる。
        オファーにエントリーした場合
        マッチングは基本、女性は2名以上なので、他の女性のSuperオファーをしないとオファーは成立しません。
        Superオファーが集まり、男性がマッチングを成立させればオファーも成立。Superオファーが集まらなかったり、男性がマッチングを成立させなかった場合は、オファー不成立になります。
        女性がマッチングが成立しているにも関わらずキャンセルをしてしまった場合
        獲得ポイントの減額、ランクの降格、悪質だった場合は使用制限させて頂くこともありますのでご注意ください。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferListItemConfirmTerm"
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cast/offerPolicy.scss";
</style>
