<template>
  <div
    class="offer-select"
    :tabindex="tabindex"
    @blur="show = false"
    :class="show ? 'active' : ''"
  >
    <div class="offer-select__value d-flex flex-wrap">
      <div class="_bg" @click="show = !show"></div>
      <div class="_item" v-for="(item, key) of Seleted" :key="key">
        {{ item.title }}

        <div class="btn-remove-option" @click="remoteOption(key)"></div>
      </div>
    </div>
    <div class="offer-select__options">
      <div
        class="offer-select__item"
        v-for="(item, key) of options"
        :class="value.indexOf(key) > -1 ? 'active' : ''"
        :key="key"
        @click="chooseOption(key)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="drop-down" @click="show = !show"></div>
  </div>
</template>

<script>
export default {
  name: "OfferSearchSelectMulti",
  props: {
    options: {
      type: Array,
      require: true
    },
    value: {
      type: Array
    },
    tabindex: {
      type: Number,
      require: null,
      default: 0
    }
  },
  computed: {
    Seleted: function() {
      let selected = [];
      this.value.map(item => {
        selected.push(this.options[item]);
      });

      return selected;
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    chooseOption(key) {
      if (this.value.indexOf(key) === -1) {
        this.value.push(key);
      }
      this.$emit("select-option", this.value);
    },
    remoteOption(key) {
      this.value.splice(key, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.offer-select {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  position: relative;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  user-select: none;
  cursor: pointer;
  .drop-down {
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    right: 0;
    &::after {
      content: "";
      position: absolute;
      right: 17px;
      top: calc(50% - 3px);
      border-top: 6px solid #9c9c9c;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transition: ease 0.5s;
    }
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &.active {
    .offer-select__options {
      opacity: 1;
      transform: scaleY(1);
    }

    .drop-down {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  &__value {
    height: 38px;
    width: 100%;
    font-family: "Hiragino Kaku Gothic Pro W3";
    text-align: left;
    padding: 7px 18px;
    margin-bottom: 3px;
    font-size: 14px;
    overflow-y: auto;
    padding-right: 40px;
    position: relative;

    ._bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    ._item {
      padding: 3px 30px 3px 9px;
      background-color: #e5e5e5;
      color: #000;
      font-size: 12px;
      border-radius: 3px;
      margin-right: 3px;
      height: fit-content;
      position: relative;
      margin-bottom: 3px;
      z-index: 1;

      .btn-remove-option {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 3px;

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 2px;
          border-radius: 2px;
          background-color: rgb(0, 0, 0);
          top: 50%;
          left: 50%;
        }

        &::before {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }

  &__options {
    position: relative;
    z-index: 2;
    opacity: 0;
    width: 100%;
    transition: ease 0.3s;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    transform-origin: top;
    transform: scaleY(0);
    box-shadow: 0 0 15px #00000029;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    font-size: 14px;
    font-family: "Hiragino Kaku Gothic Pro W3";
    &.active,
    &:hover {
      background-color: #e5e5e5;
      color: #000;
    }
  }
}
</style>
