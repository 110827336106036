<template>
  <div class="cast-offer">
    <AppContainer :useBackground="true">
      <!--      <OfferListHeader ref="header" @open-search="openSearch = true" />-->
      <CardHeader ref="header" :node="headerTitle" />
      <div id="content-scroll" class="content-scroll">
        <OfferListFilter
          v-model="offerTab"
          @choose-filter="changeTab($event)"
        />
        <div class="cast-offer__list">
          <template v-if="isLoading">
            <AppLoading />
          </template>
          <template v-else>
            <template v-if="OfferList.length > 0">
              <OfferListItem
                v-for="(item, key) in OfferList"
                :key="key"
                :data="item"
                :tabType="offerTab"
                :showMore="showMore"
                @entry-offer="entryRequestConfirm($event)"
                @cancel-offer="cancelRequestConfirm($event)"
                @open-map="openMap($event)"
              />
            </template>
            <template v-else>
              <p class="f-w6 fs-14">{{ noDataMsg[offerTab - 1] }}</p>
            </template>
          </template>
        </div>
        <scroll-loader :loader-method="getListOffer" :loader-disable="disable">
          <AppLoading v-if="page !== 1" />
          <p v-else></p>
        </scroll-loader>
      </div>
      <!-- footer -->
      <CardFooter ref="footer" prefer="order" />
      <!-- modal confirm entry  -->
      <ModalLayout ref="offerConfirmEntry" :defaultStyle="false">
        <OfferListItemConfirm
          :data="offerRequestData"
          @sent-request="sentRequestEntry()"
          @close-modal="$refs.offerConfirmEntry.closeModal()"
        />
      </ModalLayout>

      <!-- modal confirm cancel offer  -->
      <ModalLayout ref="offerCancelOffer">
        <OfferListConfirmCancel
          :offerId="offerId"
          @sent-request="sentCancelRequest()"
          @close-modal="$refs.offerCancelOffer.closeModal()"
        />
      </ModalLayout>

      <!-- modal search ads  -->
      <OfferSearch
        v-model="params"
        :show="openSearch"
        :typeTab="offerTab"
        :page="page"
        :pageSize="pageSize"
        @close-search="openSearch = false"
        @update-params="sentSearchRequest($event)"
      />

      <!-- Notify success  -->
      <OfferNotifySuccess ref="notifySuccess" />
    </AppContainer>
  </div>
</template>

<script>
// import OfferListHeader from "./OfferListHeader.vue";
import OfferListFilter from "./OfferListFilter.vue";
import OfferListItem from "./OfferListItem.vue";
import OfferSearch from "./OfferSearch.vue";
import OfferListItemConfirm from "./OfferListItemConfirm";
import OfferNotifySuccess from "./OfferNotifySuccess";
import OfferListConfirmCancel from "./OfferListConfirmCancel";
import CardFooter from "@/components/CardFooter";
import { mapGetters, mapActions } from "vuex";
import { offerTabs } from "../../constants/offer";
import CardHeader from "@/components/CardHeader.vue";

export default {
  name: "OfferList",
  components: {
    // OfferListHeader,
    OfferListFilter,
    OfferListItem,
    OfferSearch,
    OfferListItemConfirm,
    OfferListConfirmCancel,
    OfferNotifySuccess,
    CardFooter,
    CardHeader
  },
  data() {
    return {
      offerTab: 2, // 1: Superオファー中; 2: オファー依頼, 3: 応募中, 4: 参加
      offerId: null,
      offerRequestData: [],
      openSearch: false,
      page: 1,
      pageSize: 8,
      disable: false,
      params: {},
      showMore: false,
      noDataMsg: [
        // "Superオファー中のオーダーはありません。",
        "現在オファーはありません。",
        "応募中のオーダーはありません。",
        "参加のオーダーはありません。"
      ],
      headerTitle: {
        text: "Superオファー",
        isShowTitle: true,
        isShowLogo: false
      }
    };
  },
  async created() {
    let tab = this.$route?.query?.tab;
    if (tab) {
      let tabActive = offerTabs.find(item => item.url === tab);
      this.offerTab = tabActive.id;
    }

    let params = {
      user_id: this.user?.user_id,
      page: this.page,
      perpage: this.pageSize,
      type: this.offerTab,
      commit_type: "SET_OFFER_LIST_RESET"
    };

    this.requestData(params);

    // data for search modal
    await this.$store.dispatch("offerManagement/getOfferDetail");
  },

  mounted() {
    let el = document.getElementById("mypage");
    el.classList.add("router-link-active");
  },

  computed: {
    ...mapGetters({
      OfferList: "offerManagement/List",
      user: "auth/user",
      isLoading: "common/isLoading"
    })
  },
  watch: {
    // offer entry
    offerId: function() {
      let data = this.OfferList.find(item => item.ad_id === this.offerId);

      let schedule = data.scheduled_meeting_datetime
        .replace(/-/g, "/")
        .substring(0, data.scheduled_meeting_datetime.length - 3);

      this.offerRequestData = {
        schedule: schedule,
        plan: data.plan,
        ad_id: data.ad_id
      };
    },

    OfferList: function() {
      if (this.offerId) {
        let data = this.OfferList.find(item => item.ad_id === this.offerId);
        if (data != undefined) {
          let schedule = data.scheduled_meeting_datetime
            .replace(/-/g, "/")
            .substring(0, data.scheduled_meeting_datetime.length - 3);

          this.offerRequestData = {
            schedule: schedule,
            plan: data.plan,
            ad_id: data.ad_id
          };
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getList: "offerManagement/getList"
    }),
    updateChangeExpand(offerId) {
      let offerIndex = this.listOffers.findIndex(item => item.id === offerId);
      this.listOffers[offerIndex].isExpand = !this.listOffers[offerIndex]
        .isExpand;
    },
    async requestData(params) {
      this.$store.dispatch("common/setIsLoading", true);
      await this.$store
        .dispatch("offerManagement/getList", params)
        .then(response => {
          let total = response.data.total;
          if (total <= this.perpage || total < this.page * this.pageSize) {
            this.disable = true;
          } else {
            this.disable = false;
          }
          this.page++;
          if (this.page > 1) {
            this.params = params;
            this.params.page = this.page;
            this.params.commit_type = "SET_OFFER_LIST";
          }
          this.$store.dispatch("common/setIsLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("common/setIsLoading", false);
          this.disable = true;
        });
    },
    // loadmore data
    async getListOffer() {
      if (this.page === 1) return;
      await this.requestData(this.params);
    },
    // open modal confirm entry
    entryRequestConfirm(adId) {
      this.offerId = adId;
      this.$refs.offerConfirmEntry.openModal();
    },
    // sent entry request
    async sentRequestEntry() {
      this.$refs.offerConfirmEntry.closeModal();
      this.offerTab = 3;
      this.page = 1;
      this.$router.push("orders?tab=application");
      this.$store.dispatch("common/setIsLoading", true);
      await this.requestData({
        user_id: this.user?.user_id,
        page: this.page,
        perpage: this.pageSize,
        type: this.offerTab,
        commit_type: "SET_OFFER_LIST_RESET"
      });

      this.$store.dispatch("common/setIsLoading", false);
      this.$refs.notifySuccess.openNotify();
    },
    // open modal cancel entry
    cancelRequestConfirm(adId) {
      this.offerId = adId;
      this.$refs.offerCancelOffer.openModal();
    },
    // cancel entry request
    async sentCancelRequest() {
      this.$refs.offerCancelOffer.closeModal();
      if (this.page > 1) this.page--;
      this.$store.dispatch("common/setIsLoading", true);
      // get newest list data for tab applicant
      await this.$store.dispatch("offerManagement/getList", {
        user_id: this.user?.user_id,
        page: this.page,
        perpage: this.pageSize,
        type: this.offerTab,
        commit_type: "SET_OFFER_LIST_RESET"
      });

      this.$toast("キャンセルしました。", "通知", "success");
      this.$store.dispatch("common/setIsLoading", false);
    },
    // change tab
    async changeTab(tabId) {
      this.$store.dispatch("common/setIsLoading", true);
      this.offerTab = tabId;
      this.showMore = false;
      this.page = 1;
      this.params = {
        user_id: this.user?.user_id,
        page: 1,
        perpage: 8,
        type: tabId,
        commit_type: "SET_OFFER_LIST_RESET"
      };
      await this.requestData(this.params);
      this.$store.dispatch("common/setIsLoading", false);
      document.getElementById("content-scroll").scrollTop = 0;
    },
    async sentSearchRequest(value) {
      this.params = value;
      this.page = this.params.page;
      this.$store.dispatch("common/setIsLoading", true);
      await this.requestData(value);
      this.$store.dispatch("common/setIsLoading", false);
    },
    openMap(area) {
      var userAgent = window.navigator.userAgent;
      if (
        /* if we're on iOS, open in Apple Maps */
        navigator.platform.indexOf("iPhone") != -1 ||
        navigator.platform.indexOf("iPod") != -1 ||
        navigator.platform.indexOf("iPad") != -1
      )
        window.open(`maps://maps.google.com/maps?q=${area}`);
      else if (/Android/.test(userAgent)) {
        window.open(`http://maps.google.com/maps?q=${area}`);
      } else
        window.open(
          /* else use Google */
          `https://maps.google.com/maps/search/${area}`
        );
    }
  }
};
</script>

<style lang="scss">
// .tooltip .tooltip-inner {
//   border-radius: 16px;
//   padding: 15px;
//   border: none;
// }

// .tooltip .tooltip-arrow {
//   width: 0;
//   height: 0;
//   border-style: solid;
//   position: absolute;
//   margin: 5px;
//   border-color: #ffdfc7;
//   background: #ffdfc7 0% 0% no-repeat padding-box;
// }

// .tooltip[x-placement^="bottom"] {
//   margin-top: 4px;
// }

// .tooltip[x-placement^="bottom"] .tooltip-arrow {
//   border-width: 0 5px 5px 5px;
//   border-left-color: transparent !important;
//   border-right-color: transparent !important;
//   border-top-color: transparent !important;
//   top: -5px;
//   left: calc(50% - 5px);
//   margin-top: 0;
//   margin-bottom: 0;
// }

// .tooltip[aria-hidden="true"] {
//   visibility: hidden;
//   opacity: 0;
//   transition: opacity 0.15s, visibility 0.15s;
// }

// .tooltip[aria-hidden="false"] {
//   visibility: visible;
//   opacity: 1;
//   transition: opacity 0.15s;
// }

// .custom-popover {
//   font-size: 12px;
//   color: #e17c2f;
//   padding: 10px;
//   border: none;
//   background: #ffdfc7 0% 0% no-repeat padding-box;
//   border-radius: 10px;
//   opacity: 1;
//   max-width: 400px;
//   width: calc(100vw - 10px);
//   .faq {
//     color: #464d77;
//     text-decoration: underline;
//     margin-bottom: 10px;
//   }
//   p {
//     margin-bottom: 0px;
//   }
// }

.cast-offer {
  .app-container {
    &__content {
      padding: 42px 0 0;
    }
  }

  &__list {
    padding: 0 19px;
    padding-top: 80px;
  }

  &__filter {
    margin-bottom: 15px;
  }

  .avatar-box {
    width: 47px !important;
    height: 47px !important;
  }
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 152px);
  // }

  .cast-offer {
    .app-container {
      &__content {
        // padding: 61px 0 91px;
        padding: 61px 0 0;
      }
    }
    &__list {
      padding: 100px 0;
    }

    &__filter {
      margin-bottom: 25px;
    }

    .avatar-box {
      width: 79px !important;
      height: 79px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.content-scroll {
  // overflow-y: scroll;
  padding-top: 32px !important;
  padding-bottom: 100px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}
</style>
